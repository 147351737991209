.tile-header {
    font-size: 2.5rem;
    color: #fff;
}

/* homepage-hero-sect */
.homepage-hero-sect {
    height: 90vh;
    height: 550px;
    background: #000;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.homepage-hero-sect .homepage-hero-sect-inner {
    height: 100%;
    position: relative;
    width: 100%;
}

.homepage-hero-sect .homepage-welcome-message {
    position: absolute;
    background: #fff;
    bottom: 20px;
    right: 3%;
    padding: 10px 15px;
    width: 25%;
    border-radius: 4px;
    z-index: 10;
}

.homepage-hero-sect .black_overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, 1));
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
}

.homepage-hero-sect .homepage-welcome-message p {
    font-size: 1.6rem;
}

.homepage-hero-sect svg {
    stroke: #fff;
    fill: #fff;
}

.homepage-hero-sect .homepage-welcome-message h2 {
    font-size: 2rem;
    line-height: 1.3;
}

.homepage-hero-sect .homepage-welcome-message a {
    background: #000;
    padding: 10px 20px;
    display: flex;
    font-size: 1.4rem;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    border-radius: 3px;
}

.homepage-hero-sect video {
    height: 56.25vw;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 10px);
    transform: translate3d(-50%, -50%, 10px);
    width: 100vw;
    z-index: 1;
}

/* homepage-links-sect */
.img-cover {
    background: #fff;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, .2)), url('../images/main/event_1.jpg');
    background: url('../images/main/event_1.jpg');
    background-size: cover;
    background-position: center;
    height: 40rem;
    width: 100%;
    padding-top: 5%;
}

.img-cover h2 {
    color: #fff;
    font-size: 3rem;
}

.img-cover p {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 30px;
}

.img-cover .grid-2 {
    align-items: flex-start;
}

.img-cover a {
    color: #111;
    font-size: 1.5rem;
    background: #fff;
    padding: 15px 25px;
    border-radius: 4px;
}

.homepage-links-sect {
    background: #000000;
    padding: 5% 0px;
}

.homepage-links-sect img {
    height: 15rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.homepage-links-grid-cover {
    background: #1D1D1D;
    border-radius: 4px;
}

.homepage-links-sect .links-text-cover {
    padding: 10px;
}

.homepage-links-grid-cover p {
    color: #a5a5a5;
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 0px;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.homepage-links-grid-cover h3 {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 5px;
}

.homepage-links-sect p.tile-date {
    /* color: #FF0000; */
    color: #fff;
    margin-bottom: 5px;
}

.homepage-links-sect a {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: #a5a5a5;
    margin-top: 20px;
}

.homepage-links-sect .grid-flex {
    margin-bottom: 10px;
}

.homepage-links-sect .grid-flex h2 {
    margin-bottom: 0px;
}

.homepage-links-sect .grid-flex a {
    font-size: 1.6rem;
}

/* gallery-sect */
.gallery-sect {
    background: #000000;
    padding: 5% 0px;
}

.gallery-sect .center {
    /* width: 80%; */
    margin: 0px auto;
    display: block;
    text-align: center;
}

.gallery-sect .center img {
    height: 30rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.gallery-sect p {
    font-size: 1.6rem;
    color: #fff;
    width: 70%;
    margin: 0px auto;
    display: block;
    margin-bottom: 20px;
}

.gallery-sect h2 {
    font-size: 3.5rem;
    color: #fff;
    margin-bottom: 0px;
}

.gallery-sect .social-bar {
    display: flex;
    width: 10%;
    margin: 0px auto;
    grid-gap: 10px;
    text-align: center;
}

.gallery-sect .social-bar svg {
    width: 20px;
    height: 20px;
    stroke: #fff;
    background: #FF0000;
}

/* final-homepage-message */
.final-homepage-message {
    padding: 20px 0px;
}

.final-homepage-message a {
    margin-bottom: 0px;
    line-height: 1.2;
    font-size: 1.6rem;
    color: #000;
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

.final-homepage-message h2 {
    margin-bottom: 0px;
    line-height: 1.2;
    font-size: 3.5rem;
    text-transform: uppercase;
}

.final-homepage-message .grid-flex {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.final-homepage-message .links-cover {
    /* grid-gap: 80px; */
    display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}


.circular-height img {
    /* height: 50rem; */
    width: 100%;
    height: 100%;
    height: 50rem;
    object-position: center;
    object-fit: cover;
    border-radius: 3px;
}

.circular-height {
    position: relative;
}

.circular-height .yellow-cover {
    position: absolute;
    background: rgba(209, 169, 84, .6);
    background: rgba(183, 147, 74, .8);
    width: 100%;
    height: 100%;
    top: 0px;
}

.circular-cover {
    background: #000000;
}

.circular-cover .circular-link a {
    background: #FF0000;
    padding: 15px;
    width: 100%;
    display: block;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    font-family: 'FranklinGotTReg';
}

@media screen and (max-width: 720px) {
    .homepage-links-sect.recent-events .grid-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
        row-gap: 20px;
    }

    .homepage-links-sect {
        padding: 20% 0px;
    }

    .homepage-links-sect .grid-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
        row-gap: 20px;
    }

    .final-homepage-message .grid-flex {
        display: flex;
        justify-content: space-between;
    }

    .final-homepage-message .links-cover {
        display: block;
        text-align: center;
    }

    .final-homepage-message a {
        justify-content: center;
    }

    .homepage-links-grid-cover h3 {
        font-size: 1.5rem;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .homepage-links-sect img {
        height: 12rem;
    }
    .homepage-links-sect p.tile-date,
    .homepage-links-grid-cover p,
    .homepage-links-sect a {
        font-size: 1.2rem;
    }
    .homepage-links-sect .links-text-cover {
        padding: 10px 5px;
    }

    .circular-height img {
        height: 25rem;
    }

    .tile-header {
        font-size: 2rem;
    }
    .gallery-sect .center img {
        height: 20rem;
    }
    .carousel-div {
        padding-top: 10%;
    }
    .gallery-sect h2 {
        font-size: 2.2rem;
    }
    .gallery-sect {
        padding-bottom: 20%;
        padding-top: 0px;
    }

    .final-homepage-message h2 {
        font-size: 2.5rem;
        margin-bottom: 20px;
        margin-bottom: 0px;
    }
    .final-homepage-message a {
        font-size: 1.4rem;
        margin-bottom: 20px;
        margin-bottom: 0px;
    }

    .final-homepage-message a:last-child {
        margin-bottom: 0px;
    }
    .homepage-hero-sect .homepage-welcome-message {
        width: 100%;
        display: none;
    }
    .homepage-links-sect .grid-flex a {
        margin-top: 0px;
        line-height: 1;
    }

    .homepage-hero-sect .homepage-hero-sect-inner video {
        height: auto;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        overflow: hidden;
        position: absolute;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: auto;
        z-index: 0;
    }
}