@font-face {
    font-family: 'Matter Medium';
    src: url('./fonts/matter/Matter-Medium.ttf');
}

@font-face {
    font-family: 'Matter Regular';
    src: url('./fonts/matter/Matter-Regular.ttf');
}

@font-face {
    font-family: 'Matter SemiBold';
    src: url('./fonts/matter/Matter-SemiBold.ttf');
}

@font-face {
    font-family: 'Recoleta';
    src: url('./fonts/Recoleta-RegularDEMO.otf');
}

@font-face {
    font-family: 'FranklinGotTReg';
    src: url('./fonts/FranklinGotTReg\ Regular.ttf');
}

@font-face {
    font-family: 'Franklin Got Reg';
    src: url('./fonts/FranklinGotItcTEEBoo\ Regular.ttf');
}

:root {
    --red: #D1410C;
    --gray: #D1D1D1;
    --gray-font: #948D8D;
    --yellow: #A6762D;
}

* {
    margin: 0%;
    padding: 0%;
}

body,
html {
    font-family: 'Matter Regular';
    font-family: 'Franklin Got Reg';
    font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Matter SemiBold';
    font-family: 'FranklinGotTReg';
}

.contain {
    padding: 0% 3%;
}

.container {
    padding: 0% 90px;
}

.mt_5 {
    margin-top: 5%;
}

.pt_5 {
    padding-top: 5%;
}

.mt_3 {
    margin-top: 3%;
}

.grid-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-2-bias {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
}

.grid-4 img,
.grid-3 img,
.grid-2 img,
.grid-5 img {
    width: 100%;
    height: 100%;
}

.form-group {
    margin-bottom: 20px;
}

.flex_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
}

.btn_main {
    background: var(--red);
    border: 1px solid var(--red);
    color: #fff;
    padding: 12px;
    width: 100%;
}

/* footer */
.footer {
    margin-top: 5%;
}

.footer.noMargin {
    margin-top: unset;
}

.footer button.newsletter-button {
    background: transparent;
    border: 2px solid #fff;
    font-family: 'Matter SemiBold';
    font-size: 1.6rem;
    padding: 10px 35px;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 20px;
}

.footer .inner-footer-flex {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
}

.footer .social-icon svg {
    width: 15px;
    height: 15px;
    stroke: #fff;
}

.footer .footer-flex {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.footer .social-bar {
    display: flex;
    grid-gap: 10px;
}

.footer .social-icon {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .social-icon svg {
    stroke: #222;
}

.footer .footer-bottom-bar {
    border-top: 2px solid rgba(198, 198, 198, .2);
    padding-top: 5%;
    margin-top: 5%;
}

.footer .footer-bottom-bar .outer-footer-flex {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 80px;
}

.footer .footer-bottom-bar .outer-footer-flex {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 80px;
}

/* navigation */

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 3%;
    padding-left: 1.5%;
    /* border-bottom: 1px solid #948D8D; */
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.navigation img {
    width: 170px;
    height: auto;
}

.navigation ul {
    margin: 0px;
    display: flex;
    align-items: center;
    grid-gap: 15px;
}

.navigation ul li {
    list-style: none;
}

.navigation ul li a {
    color: #fff;
    font-size: 1.4rem;
    text-transform: uppercase;
}

.navigation ul li .menu-bg {
    background: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.navigation.fixed {
    background: #FCFBFB;
    box-shadow: 0px 0px 3px 4px rgba(0, 0, 0, .2);
}

.navigation.fixed ul li .menu-bg {
    background: #222222;
}

.navigation.fixed ul li a {
    color: #222222;
}

.desktop-nav ul li {
    list-style: none;
    text-align: right;
}

.desktop-nav-drawer .ant-drawer-header {
    display: none;
}

.desktop-nav-drawer .desktop-nav {
    position: relative;
    height: 100%;
}

.desktop-nav-drawer .desktop-social {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.desktop-nav-drawer .desktop-nav-inner {
    border-top: 2px solid #222222;
}

.desktop-nav-drawer .desktop-nav-inner ul {
    padding: 40px 20px;
}

.desktop-nav-drawer .ant-drawer-body {
    padding: 0px;
    background: #01757E;
}

.desktop-nav-drawer .desktop-nav-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}

.desktop-nav-drawer .desktop-nav-header svg {
    width: 20px;
    height: auto;
}

.desktop-nav-drawer .desktop-social svg {
    width: 20px;
    height: 20px;
}

.desktop-nav-drawer .desktop-social .social-bar {
    display: flex;
    grid-gap: 10px;
    justify-content: space-between;
    padding: 20px;
}

.desktop-nav-drawer .desktop-social .social-icon {
    background: #222222;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-nav-drawer .desktop-social svg {
    stroke: #fff;
}

.desktop-nav ul li a {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-family: 'FranklinGotTReg';
    color: #efefef;
}

.first_display {
    height: -webkit-max-content;
    height: max-content;
    position: -webkit-sticky;
    position: sticky;
    top: 15%;
}

/* .navigation ul li svg {
    fill: #e5f5f4;
} */


/* footer */
.footer {
    padding: 3% 0;
    background: #000;
    background: #222222;
}

.footer .biased_grid_4 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

.footer p,
.footer ul li a,
.footer a {
    color: #fff;
    line-height: 1.6;
    font-size: 1.3rem;
}

.footer ul li {
    margin-bottom: 5px;
    list-style: none;
}

.footer h5 {
    color: #fff;
    font-size: 1.4rem;
}

.footer h3 {
    font-size: 1.4rem;
    color: #fff;
}

.footer ion-icon {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
}

.footer ul li:last-child,
.footer p {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.footer.margin {
    margin-top: 5%;
}

/* antd */
.ant-table {
    background: transparent;
}

.grid-2-bias .media-cover video,
.grid-2-bias .media-cover img {
    width: 100%;
    /* height: 100%; */
    border-radius: 4px;
}

.grid-2-bias .media-cover {
    margin-bottom: 10px;
}

.grid-2.lti_grid {
    grid-gap: 10px;
}

.grid-2.lti_grid img {
    height: 30rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

/* events-page */
.events-page .homepage-links-sect.recent-events {
    background: #FCFBFB;
}

.events-page .event-bg {
    background: #222222;
    height: 400px;
    width: 100%;
}

.events-page.event-block-display .event-bg {
    background: #222222;
    height: 200px;
    width: 100%;
}

.ant-skeleton.ant-skeleton-element {
    width: 100%;
}

.ant-skeleton-element .ant-skeleton-image {
    width: 100%;
    height: 20rem;
}

.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

.new-swiper-box {
    position: absolute;
    right: 0px;
    top: -5%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carousel-div {
    position: relative;
}

.swiper {
    position: unset !important;
}

.carousel-div {
    padding-top: 2%;
}

.carousel-div .new-swiper-box svg {
    height: 3rem;
    width: 100%;
    stroke: red;
}

.carousel-div button {
    background: transparent;
    border: none;
}

@media screen and (max-width:720px) {
    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    .footer {
        display: block;
    }

    .footer .inner-footer-flex {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px;
        row-gap: 30px;
        margin-bottom: 15%;
    }

    .footer .footer-flex {
        display: block;
    }

    .footer .footer-bottom-bar .outer-footer-flex {
        display: block;
    }

    .grid-2-bias {
        display: block;
    }

    .contain {
        padding: 0px 1.5%;
    }

    .footer {
        margin-top: 15%;
        padding-top: 15%;
        padding-bottom: 10%;
    }

    .pt_5 {
        padding-top: 15%;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .desktop-nav ul li {
        text-align: left;
        border-bottom: 4px solid rgba(198, 198, 198, .2);
        padding: 12px 10px;
    }

    .desktop-nav-drawer .desktop-nav-inner {
        border-top: 4px solid rgba(198, 198, 198, .2);
    }

    .desktop-nav-drawer .desktop-nav-header {
        padding: 8px;
        padding-left: 0px;
    }

    .desktop-nav-drawer .desktop-nav-inner ul {
        padding: 40px 0px;
    }

    .desktop-nav-drawer .ant-drawer-body {
        background: #FCFBFB;
    }

    .desktop-nav ul li a {
        color: #222;
        font-size: 1.4rem;
    }

    .desktop-nav-drawer img {
        height: 45px;
        width: 120px;
    }

    .desktop-nav-drawer .desktop-social {
        display: block;
    }

    .navigation img {
        width: 130px;
        height: auto;
    }
    .navigation ul li .menu-bg {
        width: 45px;
        height: 45px;
    }
    .navigation ul li .menu-bg svg {

    }
}